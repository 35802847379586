.outline-primary-focus:focus-within,
.outline-primary-focus:focus {
  outline: 0;
  box-shadow: 0px 0px 0px 3px rgba(var(--palette-primary-main-rgb) / 0.4);
}

.outline-secondary-focus:focus-within,
.outline-secondary-focus:focus {
  outline: 0;
  box-shadow: 0px 0px 0px 3px rgba(var(--palette-secondary-main-rgb) / 0.4);
}

.outline-danger-focus:focus-within,
.outline-danger-focus:focus {
  outline: 0;
  box-shadow: 0px 0px 0px 3px rgba(var(--palette-danger-main-rgb) / 0.4);
}

.border-primary-focus:focus-within,
.border-primary-focus:focus {
  @apply border;
  border-color: var(--palette-primary-main);
}

.border-secondary-focus:focus-within,
.border-secondary-focus:focus {
  @apply border;
  border-color: var(--palette-secondary-main);
}

.border-danger-focus:focus-within,
.border-danger-focus:focus {
  @apply border;
  border-color: var(--palette-danger-main);
}

.\!outline-primary-focus:focus-within,
.\!outline-primary-focus:focus {
  outline: 0;
  box-shadow: 0px 0px 0px 3px rgba(var(--palette-primary-main-rgb) / 0.4) !important;
}

.\!border-primary-focus:focus-within,
.\!border-primary-focus:focus {
  border: 1px solid !important;
  border-color: var(--palette-primary-main) !important;
}

.border-primary-hover:hover {
  @apply border;
  border-color: var(--palette-primary-main);
}

.border-secondary-hover:hover {
  @apply border;
  border-color: var(--palette-secondary-main);
}

.border-danger-hover:hover {
  @apply border;
  border-color: var(--palette-danger-main);
}

// Radio input
input[type="radio"].radio-input-primary {
  @apply h-7 w-7 md:h-5 md:w-5 rounded-full cursor-pointer border flex items-center justify-center transition-all ease-in-out p-1 bg-input-primary border-divider-main outline-primary-focus border-primary-focus text-primary-contrast;
  @apply border-primary-focus hover:border-primary-main;

  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: var(--palette-input-primary);

  &::before {
    content: "";
    @apply w-2.5 h-2.5 rounded-full transform scale-0 transition-transform ease-in-out bg-primary-main border-primary-main animate-pulse-click-primary text-primary-contrast;
  }

  &:checked::before {
    @apply scale-100;
  }
  &:disabled {
    @apply bg-divider-main text-text-disabled border-divider-main cursor-not-allowed;
  }
}

/* No spinners for number inputs */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  @apply text-text-disabled;
  opacity: 0.5;
}

.no-arrow {
  input[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.ant-segmented-group {
  @apply text-text-primary transition-colors;

  .ant-segmented-item {
    @apply transition-colors;

    &:focus,
    &:hover {
      @apply text-text-secondary;
    }

    &.ant-segmented-item-selected {
      @apply bg-primary-main text-primary-contrast;
    }
  }

  .ant-segmented-thumb {
    @apply bg-primary-main text-primary-contrast rounded-lg overflow-hidden;
  }
}

// Custom calendar
.kanpla-calendar td:has(.calendar-range-start)::before {
  background: var(--palette-background-primary);
  position: absolute;
  z-index: 0;
  content: "";
  display: block;
  height: 100%;
  width: 50%;
  top: 0;
  left: 0;
}

.kanpla-calendar td:has(.calendar-range-end)::after {
  background: var(--palette-background-primary);
  position: absolute;
  z-index: 0;
  content: "";
  display: block;
  height: 100%;
  width: 50%;
  top: 0;
  right: 0;
}

.kanpla-calendar .calendar-day-outside:not([aria-selected]) {
  @apply text-text-disabled opacity-50;
}

.kanpla-calendar .calendary-day-today {
  @apply bg-secondary-light text-secondary-contrast;
}

.kanpla-calendar td.calendar-week-mode:nth-child(2) > button {
  border-top-left-radius: var(--border-rounded-md, 6px) !important;
  border-bottom-left-radius: var(--border-rounded-md, 6px) !important;
}

.kanpla-calendar td.calendar-week-mode:nth-child(8) > button {
  border-top-right-radius: var(--border-rounded-md, 6px) !important;
  border-bottom-right-radius: var(--border-rounded-md, 6px) !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
