#SurveyElement {
  // Title and description color
  --main-color: #{"rgb(var(--palette-primary-main-rgb))"};
  // button text color
  --body-background-color: #{"rgb(var(--palette-primary-contrast-rgb))"};
  // question title and input text color
  --text-color: #{"rgb(var(--palette-text-primary-rgb))"};
  // question background color after answer
  --answer-background-color: #{"rgba(var(--palette-primary-main-rgb) / 0.2)"};
  // checkbox input color
  --border-color: #{"rgba(var(--palette-text-primary-rgb) / 0.8)"};
  // rating input color
  --main-hover-color: #{"rgba(var(--palette-text-primary-rgb) / 0.8)"};

  // Submit button, need this to override trasparent background-color
  .sv-footer__complete-btn {
    background-color: var(--main-color);
  }

  // Text input line color
  .sv-text {
    border-color: var(--main-color);
  }

  .sv-container-modern__title {
    @apply flex flex-col gap-4;

    .sv-logo--left {
      margin: 0 !important;
    }

    .sv-header__text {
      @apply w-full;
      max-width: 100% !important;
    }
  }

  .sv-completedpage {
    @apply bg-transparent;
    margin: auto;
  }
}
