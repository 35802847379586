// Pop animation
.pop {
  animation: pop 0.3s linear 1;
}

@keyframes pop {
  50% {
    transform: scale(1.2);
  }
}

// Push animation
.push {
  animation: pulse 0.3s linear 1;
}

@keyframes push {
  50% {
    transform: scale(0.8);
  }
}

// Gradient animation
.sliding-gradient-animation {
  background-image: linear-gradient(
    to right,
    rgba(var(--palette-primary-contrast-rgb) / 1) 0%,
    rgba(var(--palette-primary-contrast-rgb) / 0.7) 33%,
    rgba(var(--palette-primary-contrast-rgb) / 0.4) 66%,
    rgba(var(--palette-primary-contrast-rgb) / 0.1) 100%
  );
  background-size: 200% 200%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient-move 3s linear infinite;
}

@keyframes gradient-move {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

// Ringing animation
.animate-ring {
  animation: ring 3s 3 ease;
  transform-origin: 50% 0px initial;
}

.animate-ring-infite {
  animation: ring 2.5s ease infinite;
  transform-origin: 50% 0px initial;
}

@keyframes ring {
  0% {
    transform: rotate(-15deg);
  }

  2% {
    transform: rotate(15deg);
  }

  4% {
    transform: rotate(-18deg);
  }

  6% {
    transform: rotate(18deg);
  }

  8% {
    transform: rotate(-22deg);
  }

  10% {
    transform: rotate(22deg);
  }

  12% {
    transform: rotate(-18deg);
  }

  14% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-12deg);
  }

  18% {
    transform: rotate(12deg);
  }

  20% {
    transform: rotate(0deg);
  }
}

// Slide-in animation
.animate-slide-in {
  animation: slide-in 1.5s linear infinite;
}

.animate-slide-in-finite {
  animation: slide-in 1.5s linear;
}

@keyframes slide-in {
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translate(0px);
  }
}

// Blink animation (flashing)
.animate-blink {
  animation: blinker 1.6s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.4;
  }
}

// Pulse-wave animation
.animate-pulse-click-primary {
  animation: pulse-primary 1.5s;
}

@keyframes pulse-primary {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--palette-primary-main-rgb) / 0.4);
  }
  70% {
    box-shadow: 0 0 0 8px rgba(var(--palette-primary-main-rgb) / 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(var(--palette-primary-main-rgb) / 0);
  }
}

.animate-pulse-click-secondary {
  animation: pulse-primary 1.5s;
}

@keyframes pulse-secondary {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--palette-secondary-main-rgb) / 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(var(--palette-secondary-main-rgb) / 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(var(--palette-secondary-main-rgb) / 0);
  }
}

// .animate-slide-up {
//   animation: slide-up 0.4s ease;
// }

.animate-slide-up-enter {
  transform: translateY(100%);
  opacity: 0;
}

.animate-slide-up-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 400ms;
}

.animate-slide-up-exit {
  transform: translateY(0);
  opacity: 1;
}

.animate-slide-up-exit-active {
  transform: translateY(100%);
  opacity: 0;
  transition: all;
}

.animate-fade-appear {
  opacity: 0;
}

.animate-fade-appear-active {
  opacity: 1;
  transition: opacity 150ms;
}

.animate-fade-enter {
  opacity: 0;
}

.animate-fade-enter-active {
  opacity: 1;
  transition: opacity 150ms;
}

.animate-fade-exit {
  opacity: 1;
}

.animate-fade-exit-active {
  opacity: 0;
  transition: opacity 150ms;
}

.animate-slide-down-enter {
  transform: translateY(-100%);
  opacity: 0;
}

.animate-slide-down-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.animate-slide-down-exit {
  transform: translateY(0);
  opacity: 1;
}

.animate-slide-down-exit-active {
  transform: translateY(-100%);
  opacity: 0;
  transition: all 200ms;
}

.animate-bar-chart-loading {
  animation: bar-chart-loading 1.8s infinite ease-in-out;
}

@keyframes bar-chart-loading {
  0% {
    height: 10%;
  }
  50% {
    height: 100%;
  }
  100% {
    height: 10%;
  }
}

.animate-bar-chart {
  transform: scaleY(0);
  transition: transform 400ms ease-in-out;
  transform-origin: bottom;
}
