@font-face {
  font-family: "Inter Var";
  src: url("/fonts/Inter.var.woff2");
  font-weight: 1 999;
}

:root {
  --status-bar-height: 0px;

  --brand-background-secondary: rgb(240, 255, 244);
  --brand-background-secondary: rgb(198, 246, 213);
  --brand-primary-light: rgb(154, 230, 180);
  --brand-primary-light: rgb(104, 211, 145);
  --brand-primary-main: rgb(72, 187, 120);
  --brand-primary-main: rgb(56, 161, 105);
  --brand-primary-dark: rgb(47, 133, 90);
  --brand-primary-dark: rgb(39, 103, 73);
  --brand-primary-dark: rgb(34, 84, 61);
}

#reepay-token-iframe,
div[id^="rp_checkout_overlay"],
div[id*=" rp_checkout_overlay"] {
  padding-top: calc(var(--status-bar-height) + 0.75rem);
  background: white;
}

html {
  font-family: "Inter", sans-serif;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  scroll-behavior: smooth;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter Var", sans-serif;
    font-variation-settings: normal;
  }
}

body {
  scroll-behavior: smooth;
  max-width: 100vw;
}

body > div:first-child,
#__next {
  height: 100%;
}

.wrapper {
  @apply max-w-7xl px-4 md:px-6 mx-auto;
}

::selection {
  @apply bg-primary-light text-primary-contrast #{!important};
}

.scrollable {
  @apply overflow-y-scroll;
  -webkit-overflow-scrolling: touch;
  overflow-scolling: touch;
  overflow-behaviour: contain;
}

.grey-scale {
  @apply bg-text-disabled;
  transition: all 0.1s ease-in;
}

.grey-scale:hover {
  transform: scale(1.2);
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.remove-all-styles {
  all: revert !important;
}

.bg-input-primary {
  background-color: rgb(var(--palette-input-primary-rgb));
}

.bg-input-secondary {
  background-color: rgb(var(--palette-input-secondary-rgb));
}

.availability-collapse .ant-collapse-item {
  border-color: rgb(
    var(--palette-warning-main-rgb, 237 137 54) / var(--tw-border-opacity)
  );
}
.availability-collapse .ant-collapse-expand-icon svg {
  color: rgb(
    var(--palette-warning-main-rgb, 237 137 54) / var(--tw-border-opacity)
  );
}
.availability-collapse .ant-collapse-header {
  padding: 0 !important;
}

.sv-rating__item-star {
  @apply opacity-50 transition-opacity;

  &.sv-rating__item-star--selected {
    @apply opacity-100;
  }
}

@import "fontawesome";

// Override button appearance for Apple Pay
#adyen-payment-checkout > button {
  -webkit-appearance: -apple-pay-button;
  display: flex;
  width: 100%;
}
