// Texts and links
a:not(.button-link) {
  @apply transition-colors text-primary-main;

  &:hover {
    @apply text-primary-dark;
  }
}

//Texts inside links
.link-color-normal {
  h3 {
    @apply text-text-primary;
  }
}

// Background colors
.kanpla-bg-primary {
  @apply bg-background-primary transition-colors;
}

.kanpla-bg-primary.hoverable:hover {
  @apply bg-background-secondary;
}

/** 
 * Fix for weird scrolling issue for Safari
 * https://www.notion.so/kanpla/Cannot-scroll-da298384bad94295ab7ef06084113788
 */
.rc-virtual-list-holder {
  scroll-behavior: initial !important;
}

/** Extra classes */
::placeholder,
.form-input::placeholder,
.form-textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  @apply text-divider-main;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  @apply text-divider-main;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  @apply text-divider-main;
}

input,
.form-input {
  @apply border-divider-main border-opacity-50 bg-background-primary;
}

.loader {
  @apply bg-gradient-to-br from-divider-main to-background-secondary;
}

.react-code-input {
  display: grid !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}

a.hoverable {
  &:focus,
  &:active {
    @apply no-underline #{!important};
  }

  img {
    @apply transform transition-all #{!important};
  }

  &:hover {
    img {
      @apply scale-110;
    }
  }
}

.text-area-options {
  @apply leading-relaxed;

  span.highlight {
    @apply p-0.5 px-1 border rounded-md bg-background-secondary text-sm;
  }

  .public-DraftEditor-content {
    *[data-block="true"] {
      float: left;

      .enter {
        width: 100vw;
        display: block;
      }
    }
  }

  .DraftEditor-root,
  .DraftEditor-editorContainer,
  .public-DraftEditor-content,
  div[data-contents="true"] {
    @apply h-full;
  }
}

/** LOGO */
.logo-default {
  .primary,
  .secondary {
    @apply fill-text-primary;
  }
  &:not(.monotone) .secondary {
    @apply fill-primary-main;
  }
}

.logo-inverted {
  .primary,
  .secondary {
    @apply fill-background-primary;
  }
  &:not(.monotone) .secondary {
    @apply fill-primary-main;
  }
}

.logo-white {
  .primary,
  .secondary {
    @apply fill-white;
  }
  &:not(.monotone) .secondary {
    @apply fill-white;
  }
}

/** Anonymous utilities */
.translate-bottom {
  top: 100%;
  transform: translate(0, -100%);
}

.bg-white-opacity-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 20%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.9) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.authentication-button {
  .vertical-line {
    border-width: 1px;
    border-color: var(--palette-text-primary);
  }
}

td.col-codes {
  height: 70px;
  @apply p-0 #{!important};
}

td.col-codes:hover {
  @apply bg-gray-300 cursor-pointer #{!important};
}

/** Kanpla Message */
.Toastify__toast-body {
  @apply m-0;
}

.Toastify__toast {
  @apply min-h-0;
}

.Toastify__toast-icon {
  margin: 0 6px 0 0;
}

/** Kanpla Select */
.kanpla_select__indicator-separator {
  @apply rounded-full bg-gray-400;
}

/** Added due to select width issues */
.kanpla_select__input-container {
  @apply overflow-hidden;
}

/** Added due to select width issues */
.kanpla_select__multi-value {
  @apply px-1 mr-2 bg-background-secondary rounded;
}

.kanpla_select__value-container {
  @apply justify-start;
}

/** React code input */
.react-code-input-wrapper {
  @apply w-full grid grid-cols-4 gap-x-1.5 place-items-center capitalize;
}

.react-code-input-wrapper > input {
  @apply rounded-md text-center p-3 border-2 font-medium bg-input-primary capitalize;
  width: 80px;
  height: 90px;
  font-size: 42px;
}

.react-code-input-wrapper > input:not([data-valid="false"]) {
  border-color: var(--palette-secondary-main);

  &:focus,
  &:focus-within {
    outline: 0;
    box-shadow: 0px 0px 0px 3px rgba(var(--palette-primary-main-rgb) / 0.4) !important;
    border-color: var(--palette-primary-main);
  }
}

.react-code-input-wrapper > input:not([data-valid="true"]) {
  border-color: var(--palette-secondary-main);

  &:focus,
  &:focus-within {
    outline: 0;
    box-shadow: 0px 0px 0px 3px rgba(var(--palette-danger-main-rgb) / 0.4);
    border-color: var(--palette-danger-main);
  }
}
