/**
 * Fix for Chrome issue #273306
 * Src: https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.4.1/paper.css
 **/
.print,
.print-sheet {
  /* Print Sheet sizes in mm. */
  --sheetHeight: 297mm;
  --sheetWidth: 210mm;

  &.A3 {
    --sheetHeight: 420mm;
    --sheetWidth: 297mm;

    &.landscape {
      --sheetHeight: 297mm;
      --sheetWidth: 420mm;
    }
  }

  &.A4 {
    --sheetHeight: 297mm;
    --sheetWidth: 210mm;

    &.landscape {
      --sheetHeight: 210mm;
      --sheetWidth: 297mm;
    }
  }

  &.A5 {
    --sheetHeight: 210mm;
    --sheetWidth: 148.5mm;

    &.landscape {
      --sheetHeight: 148.5mm;
      --sheetWidth: 210mm;
    }
  }

  &.A6 {
    --sheetHeight: 148.5mm;
    --sheetWidth: 105mm;

    &.landscape {
      --sheetHeight: 105mm;
      --sheetWidth: 148.5mm;
    }
  }

  &.A7 {
    --sheetHeight: 105mm;
    --sheetWidth: 74mm;

    &.landscape {
      --sheetHeight: 74mm;
      --sheetWidth: 105mm;
    }
  }

  &.A8 {
    --sheetHeight: 74mm;
    --sheetWidth: 52mm;

    &.landscape {
      --sheetHeight: 52mm;
      --sheetWidth: 74mm;
    }
  }

  &.A9 {
    --sheetHeight: 52mm;
    --sheetWidth: 37mm;

    &.landscape {
      --sheetHeight: 37mm;
      --sheetWidth: 52mm;
    }
  }

  &.A10 {
    --sheetHeight: 37mm;
    --sheetWidth: 26mm;

    &.landscape {
      --sheetHeight: 26mm;
      --sheetWidth: 37mm;
    }
  }

  &.letter {
    --sheetHeight: 279mm;
    --sheetWidth: 216mm;

    &.landscape {
      --sheetHeight: 216mm;
      --sheetWidth: 279mm;
    }
  }

  &.legal {
    --sheetHeight: 356mm;
    --sheetWidth: 216mm;

    &.landscape {
      --sheetHeight: 216mm;
      --sheetWidth: 356mm;
    }
  }
}

.print-sheet {
  min-width: calc(var(--sheetWidth) - 1px);
  min-height: calc(var(--sheetHeight) - 1px);

  &.custom-labels {
    max-width: calc(var(--sheetWidth) - 1px);
    max-height: calc(var(--sheetHeight) - 1px);
  }
}

.print {
  @apply fixed opacity-0 pointer-events-none;

  &.debug {
    @apply opacity-100 pointer-events-auto;

    z-index: 99999;
    display: block;
    position: fixed;
    width: 210mm;
    height: 297mm;
    left: 0%;
    top: 50%;
    transform: translateY(-50%);
    background: white;
    min-height: auto;
    overflow: scroll;
    box-shadow: 0px 0px 500px rgba(0, 0, 0, 0.5);
  }
}

@page {
  margin: 1rem;
}

.print-page {
  -webkit-page-break-after: always;
  -webkit-page-break-before: avoid;
  page-break-after: always;
  page-break-before: avoid;

  &.print-page-menu-size {
    display: grid;
    min-height: var(--pageHeight);
  }
}

.print-single-page {
  height: calc(100vh - 1px);
}

.chrome-break {
  page-break-inside: avoid;
  page-break-before: auto;
  page-break-after: auto;

  td {
    page-break-after: auto;
    page-break-before: auto;
    page-break-inside: avoid;
  }
}

@media print {
  html,
  body {
    background-color: white;
    overflow: initial;
    height: initial;
  }

  // Hides every small tag which isn't inside .print i.e. modals, popups etc
  body > :not(.print) {
    display: none;
  }

  body > div:first-child,
  #__next {
    overflow: initial;
    height: initial;
  }

  .print {
    display: block;
    @apply opacity-100 relative;
  }

  .print > * {
    /* Chrome, Safari 6 – 15.3, Edge */
    -webkit-print-color-adjust: exact !important;
    /* Firefox 48 – 96 */
    color-adjust: exact !important;
    /* Firefox 97+, Safari 15.4+ */
    print-color-adjust: exact !important;
  }

  table {
    @apply w-full bg-background-primary;
    -webkit-print-color-adjust: exact;
  }

  tr {
    page-break-inside: avoid;
    -webkit-print-color-adjust: exact;
  }

  td {
    -webkit-print-color-adjust: exact;
  }

  /* Hide all scrollbars from print papers */
  * {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }

  .print-avoid-inside-break {
    page-break-inside: avoid;

    &::after {
      content: "";
      display: none;
      height: 100px;
      margin-bottom: -100px;
    }
  }
}

.label-printer {
  @media print {
    * {
      color: black !important;
    }
  }

  .single-label {
    page-break-before: always;
    page-break-after: always;
    height: 100vh !important;
    width: 100vw !important;
    padding: 0.1rem 0.4rem 0.6rem !important;
  }
}

.ant-table-tbody > tr.is-category {
  background-color: #f7fafc !important;

  & > td {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    background-color: #f7fafc !important;
    border-top: 2px solid #f3f4f6 !important;

    & > * {
      font-weight: 400 !important;
      color: #374151 !important;
    }

    &:nth-child(n + 3) {
      & > * {
        opacity: 0;
      }
    }
  }
}

.table-print.ant-table-wrapper {
  table {
    border: 1px solid black;
    border-radius: 0;

    th,
    td {
      border-bottom: 0;
    }

    .ant-table-thead > tr > th {
      background: none !important;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table-summary > tr > td {
      padding: 4px !important;
    }

    .ant-table-tbody {
      .ant-table-row:nth-child(1) {
        & > td {
          border-top: 2px solid black;
        }
      }
    }

    .ant-table-summary {
      tr > td {
        border-top: 2px solid black;
      }
    }

    .ant-table-tbody > tr.table-total {
      &.has-border-top {
        & > td {
          border-top: 3px solid black;
        }
      }

      &.has-border-bottom {
        & > td {
          border-bottom: 3px solid black;
        }
      }
    }

    .ant-table-tbody > tr.empty-cells {
      & > td:not(.first-column) {
        color: transparent;
      }
    }
  }

  &.every-line {
    table .ant-table-tbody {
      .ant-table-row {
        & > td {
          border-top: 1px solid black;
        }
      }
    }
  }

  &:not(.no-alternate) {
    table .ant-table-tbody {
      td {
        border-top: 1px solid rgba(0, 0, 0, 0.05);
      }
      .ant-table-row:nth-child(3n - 2) {
        & > td {
          border-top: 1px solid black;
        }
      }
    }
  }

  &.route-planning-table {
    table .ant-table-tbody {
      td {
        border-top: 1px solid rgba(0, 0, 0, 0.05);
      }
      .ant-table-row.is-car {
        & > td {
          border-top: 1px solid black;
        }
      }
    }
  }
}
