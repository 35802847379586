/** Antd Modal - Fullscreen **/
.ant-modal.full-screen-modal {
  padding: 0;

  .ant-modal-content {
    border-radius: 0;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .ant-modal-body {
      flex: 1;
    }
  }

  &.no-padding .ant-modal-body {
    padding: 0;
  }

  &.close-button-left .ant-modal-close {
    left: 0;
    right: unset;
  }
  &.close-button-right .ant-modal-close {
    left: unset;
    right: 0;
  }
}

/** Antd Table **/
.ant-table {
  th.ant-table-cell {
    &.highlighted {
      @apply bg-purple-200 text-purple-700 bg-opacity-50 border-purple-200;
    }
  }

  td.highlighted {
    @apply bg-purple-200 text-purple-700 bg-opacity-10 border-purple-200;
  }
}

/** Antd Form styles **/

.ant-form-item-label > label,
.ant-form p,
.form-label-antd {
  color: theme("colors.text.secondary") !important;
}

.danger {
  .ant-select-selector {
    background-color: theme("colors.danger.light") !important;
    * {
      color: #fff;
    }
  }
  .ant-select-arrow {
    * {
      color: #fff;
    }
  }
}

.secondary {
  .ant-select-selector {
    background-color: theme("colors.background.secondary") !important;
  }
}

/* Add this rule cause the ! operator of tailwind is not working */
.hidden-important {
  display: none !important;
}

.form-label-antd-layout label {
  width: 100%;
}

.form-label-antd-h-unset label {
  height: unset;
}

.ant-form-item-explain-error {
  @apply form-error mt-1 mb-2 font-medium;
}

.form-item-flex .ant-form-item-control-input-content {
  @apply flex;
}

/*
 * Need It to remove the position relative from two antd elements, because the EmojiPicker go under the form item
 * COMPONENT: Body.tsx/65 (apps/backend/components/communication/notifications)
 */
.form-item-no-relative .ant-col,
.form-item-no-relative .ant-form-item-control-input {
  @apply position-unset;
}

.ant-col {
  min-height: unset !important;
}

.ant-form-item.min-h-fit .ant-form-item-control-input {
  min-height: fit-content;
}

/** Antd Button styles **/
/* ANT design colors */
.ant-btn:not(:disabled):not(.nobg) {
  background: none;
  @apply transition-colors #{!important};
}

.ant-btn:not(:disabled):not(.nobg) {
  @apply bg-secondary-main bg-opacity-60 text-secondary-contrast border-divider-main border-opacity-70 border #{!important};

  &:hover,
  &:focus,
  &:active {
    @apply bg-secondary-main bg-opacity-100 border-opacity-100 border-divider-main #{!important};
  }
}

.ant-btn.ant-btn-primary:not(:disabled):not(.nobg) {
  @apply bg-primary-main text-primary-contrast border-primary-dark border #{!important};

  &:hover,
  &:focus,
  &:active {
    @apply bg-primary-dark border-primary-dark #{!important};
  }
}

.ant-btn.ant-btn-dangerous:not(:disabled):not(.nobg) {
  @apply bg-danger-main text-danger-contrast border-danger-dark border #{!important};

  &:hover,
  &:focus,
  &:active {
    @apply bg-danger-dark border-danger-dark #{!important};
  }
}

.ant-btn-link,
.ant-btn-link:not(:disabled):not(.nobg) {
  @apply text-primary-main bg-transparent #{!important};
}

.ant-btn-link:hover,
.ant-btn-link:focus {
  @apply text-primary-dark #{!important};
}

.ant-btn-link,
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-text,
.ant-btn-text:hover,
.ant-btn-text:focus,
.ant-btn-link:not(:disabled):not(.nobg),
.ant-btn-link:not(:disabled):not(.nobg):hover,
.ant-btn-link:not(:disabled):not(.nobg):focus,
.ant-btn-text:not(:disabled):not(.nobg),
.ant-btn-text:not(:disabled):not(.nobg):hover,
.ant-btn-text:not(:disabled):not(.nobg):focus {
  @apply bg-transparent text-current border-none #{!important};
}

.ant-btn-text,
.ant-btn-text:hover,
.ant-btn-text:focus {
  @apply text-current #{!important};
}

.ant-btn-link:hover,
.ant-btn-link:focus {
  @apply text-primary-dark #{!important};
}

.ant-card {
  @apply border #{!important};
}

.ant-switch {
  @apply bg-background-secondary;

  &.ant-switch-checked {
    @apply bg-primary-main;
  }
}

.ant-switch-handle::before {
  @apply text-primary-contrast;
}

.ant-badge.custom-badge {
  &.primary .ant-badge-count {
    @apply bg-primary-main text-primary-contrast ring-1 ring-background-primary #{!important};
  }
  &.secondary .ant-badge-count {
    @apply bg-secondary-main text-secondary-contrast ring-1 ring-background-primary #{!important};
  }

  &.primary .ant-badge-dot {
    @apply bg-primary-main ring-2 ring-background-primary #{!important};
  }
  &.secondary .ant-badge-dot {
    @apply bg-secondary-main ring-2 ring-background-primary #{!important};
  }
}

/** Customization Tree component */
.choose-group-modal,
.choose-school-modal {
  .ant-tree {
    .ant-tree-node-content-wrapper {
      @apply p-1;
    }
    .ant-tree-node-content-wrapper.ant-tree-node-selected {
      @apply bg-transparent;
    }
  }
}

.tree-scope-input {
  span[title] {
    @apply w-full;
  }

  .ant-tree-treenode {
    @apply w-full;
  }
}

.ant-tree-treenode {
  @apply w-full;
}

.module-switch {
  .ant-tree-treenode {
    @apply p-0;
  }
  .ant-tree-switcher,
  .ant-tree-indent {
    @apply h-0;
  }
  .ant-tree-node-content-wrapper {
    @apply min-h-0;
  }
  .ant-tree-indent {
    line-height: 0;
  }
}

/** Customization  */
.custom-tree {
  .ant-tree-switcher {
    line-height: 44px;
  }
  .ant-tree-node-content-wrapper {
    @apply p-1;
  }
  .ant-tree-node-content-wrapper.ant-tree-node-selected {
    @apply bg-transparent;
  }
}

/** Customization libs/ui/.../ProductBankInput.tsx modal */
.choose-group-modal {
  .selected-node {
    .anticon {
      @apply text-primary-main;
    }
  }
  .ant-tree-list {
    .ant-tree-switcher {
      line-height: 44px;
    }
  }
}

/** Customization libs/ui/.../ScopeInput.tsx modal */
.choose-school-modal {
  .select-all-checkbox {
    .ant-checkbox-inner {
      @apply rounded-lg;
    }
  }
  .ant-tree-list {
    .ant-tree-checkbox {
      @apply mt-2 mr-2 transform scale-125 lg:scale-110 xl:scale-100;
    }
    .ant-tree-switcher {
      line-height: 32px;
    }
  }
}

/** Custom tree **/
.kanplaCustomTree .ant-tree-switcher {
  z-index: 2 !important;
  padding-left: 10px;
  padding-right: 200px;
  margin-right: -200px;
}

.kanplaCustomTree .ant-tree-node-content-wrapper {
  padding-left: 20px !important;
}
