@tailwind base;
@tailwind components;

/* purgecss start ignore */

* {
  border-color: var(--palette-divider-main, #e2e8f0);
  scroll-behavior: smooth;
}

// DESIGN BASE
@import "app";
@import "typography";
@import "buttons";
@import "forms";
@import "antd";
@import "backgrounds";
@import "inputs";
@import "animations";

// SPECIAL FUNCTIONS
@import "print";

// COMPONENTS
// @import "navbar_wrapper";
// @import "switch";
@import "survey";
@import "adyen-web";

// FRONTEND (only)
@import "react_dates";
@import "custom";
@import "landing_page";

// Bottom sheet
[data-rsbs-overlay] {
  @apply max-w-md;
  margin-left: auto;
  margin-right: auto;
  z-index: 20;
}

[data-rsbs-backdrop] {
  z-index: 998;
}

:root {
  --fa-primary-color: currentColor;
  --fa-secondary-color: currentColor;
}

html {
  @apply overflow-x-hidden;
  -webkit-overflow-scrolling: touch;
}

html,
body {
  @apply bg-background-primary text-text-primary print:bg-white;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  html,
  body {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
  }
}

// Remove carousel blue outline
.focusRing___1airF.carousel__slide-focus-ring {
  outline: none !important;
}

// Full height carousel
.slide___3-Nqo {
  height: 100% !important;
}

@media (max-width: 768px) {
  .intercom-messenger-frame,
  iframe[name="intercom-banner-frame"] {
    top: var(--status-bar-height) !important;
    max-height: calc(100% - var(--status-bar-height)) !important;
  }
}

/* purgecss end ignore */

@tailwind utilities;
