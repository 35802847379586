button:focus-visible {
  @apply outline-none ring ring-primary-light border-primary-main;
}

button:focus {
  @apply outline-none;
}

a:focus {
  @apply outline-none underline;
}

a:focus-visible {
  @apply outline-none ring ring-primary-light border-primary-main underline;
}

input:focus-visible {
  @apply outline-none;
}

.btn {
  @apply rounded-lg px-4 py-2 bg-background-secondary text-text-primary transition-all duration-100 bg-gradient-to-b from-background-secondary to-gray-300;
  transform-origin: center center;
  background-size: 400% 400%;
  background-position: top;

  &:hover {
    // transform: scale(1.01);
    background-position: center;
  }

  &:active {
    @apply shadow-none text-text-secondary;
    transform: scale(0.99);
    background-position: bottom;
  }

  // green
  &.primary {
    @apply text-background-primary;
    background: linear-gradient(
      180deg,
      var(--brand-primary-main) 0%,
      var(--brand-primary-dark) 100%
    );
    background-size: 400% 400%;

    &:hover {
      background-position: center;
    }

    &:active {
      // @apply text-background-secondary;
      color: var(--brand-background-secondary);
      background-position: bottom;
    }
  }

  // talwind css variables plugin
  // blue
  &.blue,
  &.neutral {
    @apply text-background-primary from-info-main to-info-dark;
    background-size: 400% 400%;

    &:hover {
      background-position: center;
    }

    &:active {
      @apply text-info-light;
      background-position: bottom;
    }
  }

  // black
  &.black {
    @apply text-background-primary from-text-secondary to-text-primary;
    background-size: 400% 400%;

    &:hover {
      background-position: center;
    }

    &:active {
      @apply text-info-light;
      background-position: bottom;
    }
  }

  // red
  &.danger,
  &.red {
    @apply text-danger-dark from-danger-light to-danger-light;
    background-size: 400% 400%;
    background-position: top;

    &:hover {
      background-position: center;
    }

    &:active {
      background-position: bottom;
    }
  }

  // outline
  &.secondary,
  &.outline {
    background: unset;
    @apply border border-divider-main bg-background-secondary text-text-secondary;

    &:hover {
      @apply bg-background-primary;
    }
    &:active {
      @apply bg-background-secondary text-text-secondary border-divider-main;
    }
  }

  // sizes
  &.micro {
    @apply py-1 px-3 text-sm rounded;
  }

  &.lg {
    @apply py-3 px-5;
  }

  &.puffy {
    @apply py-5 px-6 text-lg;
  }

  &.auto {
    @apply py-2;
  }

  &.full {
    @apply min-w-full;
  }

  &:disabled,
  &.disabled {
    @apply text-text-disabled cursor-default pointer-events-none from-gray-300 to-gray-300;
  }
}

.btn-green:hover {
  @apply bg-primary-dark;
}

/** Button white (Kanpla Design System) */
.bg-white-main {
  @apply bg-white;
}

.text-white-contrast {
  @apply text-text-primary;
}
